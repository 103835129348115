<template>
  <div>
    <div v-if="!editMode">
      <g-title>完成情况</g-title>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">类型</div> </a-col>
        <a-col :span="6"> <div class="py-3">分数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>提前</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.ahead.score }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>按时</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.onTime.score }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>拖稿</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.delay.score }}</label>
        </a-col>
      </a-row>

      <g-title>软硬伤</g-title>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">类型</div> </a-col>
        <a-col :span="6"> <div class="py-3">扣分</div> </a-col>
        <a-col :span="6"> <div class="py-3">满分</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>错翻数</label>
        </a-col>
        <a-col :span="6">
          <label>- {{ formData.data.wrongTranslate.minusScore }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.wrongTranslate.maxScore }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>硬伤数</label>
        </a-col>
        <a-col :span="6">
          <label>- {{ formData.data.hardInjury.minusScore }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.hardInjury.maxScore }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>软伤数</label>
        </a-col>
        <a-col :span="6">
          <label>- {{ formData.data.softInjury.minusScore }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.softInjury.maxScore }}</label>
        </a-col>
      </a-row>

      <g-title>流畅度</g-title>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">等级</div> </a-col>
        <a-col :span="6"> <div class="py-3">修改量</div> </a-col>
        <a-col :span="6"> <div class="py-3">分数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>高</label>
        </a-col>
        <a-col :span="6">
          <label>＜ {{ formData.data.highFluency.modifierCount }} %</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.highFluency.score }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>中</label>
        </a-col>
        <a-col :span="6">
          <label>＜ {{ formData.data.mediumFluency.modifierCount }} %</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.mediumFluency.score }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>低</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.lowFluency.modifierCount }} %</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.lowFluency.score }}</label>
        </a-col>
      </a-row>
    </div>
    <div v-else>
      <g-title>完成情况</g-title>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">类型</div> </a-col>
        <a-col :span="6"> <div class="py-3">分数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>提前</label>
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.ahead.score" placeholder="请输入分数" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>按时</label>
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.onTime.score" placeholder="请输入分数" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>拖稿</label>
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.delay.score" placeholder="请输入分数" />
        </a-col>
      </a-row>

      <g-title>软硬伤</g-title>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">类型</div> </a-col>
        <a-col :span="6"> <div class="py-3">扣分</div> </a-col>
        <a-col :span="6"> <div class="py-3">满分</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>错翻数</label>
        </a-col>
        <a-col :span="6">
          <a-input prefix="-" v-model="formData.data.wrongTranslate.minusScore" placeholder="请输入分数" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.wrongTranslate.maxScore" placeholder="请输入分数" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>硬伤数</label>
        </a-col>
        <a-col :span="6">
          <a-input prefix="-" v-model="formData.data.hardInjury.minusScore" placeholder="请输入分数" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.hardInjury.maxScore" placeholder="请输入分数" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>软伤数</label>
        </a-col>
        <a-col :span="6">
          <a-input prefix="-" v-model="formData.data.softInjury.minusScore" placeholder="请输入分数" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.softInjury.maxScore" placeholder="请输入分数" />
        </a-col>
      </a-row>

      <g-title>流畅度</g-title>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">等级</div> </a-col>
        <a-col :span="6"> <div class="py-3">修改量</div> </a-col>
        <a-col :span="6"> <div class="py-3">分数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>高</label>
        </a-col>
        <a-col :span="6">
          <a-input
            prefix="＜"
            v-model="formData.data.highFluency.modifierCount"
            placeholder="请输入修改量"
            suffix="%"
          />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.highFluency.score" placeholder="请输入分数" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>中</label>
        </a-col>
        <a-col :span="6">
          <a-input
            prefix="＜"
            v-model="formData.data.mediumFluency.modifierCount"
            placeholder="请输入修改量"
            suffix="%"
          />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.mediumFluency.score" placeholder="请输入分数" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>低</label>
        </a-col>
        <a-col :span="6">
          <a-input prefix=">" v-model="formData.data.lowFluency.modifierCount" placeholder="请输入修改量" suffix="%" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.lowFluency.score" placeholder="请输入分数" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    propsFormData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      labelWidth: '140',
      layout: {
        labelCol: {
          style: 'width: 140px',
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      },
      formData: this.propsFormData,
    }
  },
}
</script>

<style scoped>
label {
  line-height: 30px;
}

.py-3 {
  font-weight: bold;
  color: #333;
}
</style>