var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.editMode
      ? _c(
          "div",
          [
            _c("g-title", [_vm._v("完成情况")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("类型")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("分数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("提前")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v(_vm._s(_vm.formData.data.ahead.score))]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("按时")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v(_vm._s(_vm.formData.data.onTime.score))]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("拖稿")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v(_vm._s(_vm.formData.data.delay.score))]),
                ]),
              ],
              1
            ),
            _c("g-title", [_vm._v("软硬伤")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("类型")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("扣分")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("满分")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("错翻数")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "- " + _vm._s(_vm.formData.data.wrongTranslate.minusScore)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.formData.data.wrongTranslate.maxScore)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("硬伤数")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "- " + _vm._s(_vm.formData.data.hardInjury.minusScore)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.formData.data.hardInjury.maxScore)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("软伤数")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "- " + _vm._s(_vm.formData.data.softInjury.minusScore)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.formData.data.softInjury.maxScore)),
                  ]),
                ]),
              ],
              1
            ),
            _c("g-title", [_vm._v("流畅度")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("修改量")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("分数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("高")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "＜ " +
                        _vm._s(_vm.formData.data.highFluency.modifierCount) +
                        " %"
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.formData.data.highFluency.score)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("中")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "＜ " +
                        _vm._s(_vm.formData.data.mediumFluency.modifierCount) +
                        " %"
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.formData.data.mediumFluency.score)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("低")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(_vm.formData.data.lowFluency.modifierCount) +
                        " %"
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.formData.data.lowFluency.score)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("g-title", [_vm._v("完成情况")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("类型")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("分数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("提前")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.ahead.score,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.data.ahead, "score", $$v)
                        },
                        expression: "formData.data.ahead.score",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("按时")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.onTime.score,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.data.onTime, "score", $$v)
                        },
                        expression: "formData.data.onTime.score",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("拖稿")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.delay.score,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.data.delay, "score", $$v)
                        },
                        expression: "formData.data.delay.score",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("g-title", [_vm._v("软硬伤")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("类型")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("扣分")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("满分")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("错翻数")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "-", placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.wrongTranslate.minusScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.wrongTranslate,
                            "minusScore",
                            $$v
                          )
                        },
                        expression: "formData.data.wrongTranslate.minusScore",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.wrongTranslate.maxScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.wrongTranslate,
                            "maxScore",
                            $$v
                          )
                        },
                        expression: "formData.data.wrongTranslate.maxScore",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("硬伤数")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "-", placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.hardInjury.minusScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.hardInjury,
                            "minusScore",
                            $$v
                          )
                        },
                        expression: "formData.data.hardInjury.minusScore",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.hardInjury.maxScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.hardInjury,
                            "maxScore",
                            $$v
                          )
                        },
                        expression: "formData.data.hardInjury.maxScore",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("软伤数")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "-", placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.softInjury.minusScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.softInjury,
                            "minusScore",
                            $$v
                          )
                        },
                        expression: "formData.data.softInjury.minusScore",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.softInjury.maxScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.softInjury,
                            "maxScore",
                            $$v
                          )
                        },
                        expression: "formData.data.softInjury.maxScore",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("g-title", [_vm._v("流畅度")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("修改量")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("分数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("高")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: {
                        prefix: "＜",
                        placeholder: "请输入修改量",
                        suffix: "%",
                      },
                      model: {
                        value: _vm.formData.data.highFluency.modifierCount,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.highFluency,
                            "modifierCount",
                            $$v
                          )
                        },
                        expression: "formData.data.highFluency.modifierCount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.highFluency.score,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.data.highFluency, "score", $$v)
                        },
                        expression: "formData.data.highFluency.score",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("中")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: {
                        prefix: "＜",
                        placeholder: "请输入修改量",
                        suffix: "%",
                      },
                      model: {
                        value: _vm.formData.data.mediumFluency.modifierCount,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.mediumFluency,
                            "modifierCount",
                            $$v
                          )
                        },
                        expression: "formData.data.mediumFluency.modifierCount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.mediumFluency.score,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.mediumFluency,
                            "score",
                            $$v
                          )
                        },
                        expression: "formData.data.mediumFluency.score",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("低")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: {
                        prefix: ">",
                        placeholder: "请输入修改量",
                        suffix: "%",
                      },
                      model: {
                        value: _vm.formData.data.lowFluency.modifierCount,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.lowFluency,
                            "modifierCount",
                            $$v
                          )
                        },
                        expression: "formData.data.lowFluency.modifierCount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入分数" },
                      model: {
                        value: _vm.formData.data.lowFluency.score,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.data.lowFluency, "score", $$v)
                        },
                        expression: "formData.data.lowFluency.score",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }